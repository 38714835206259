<!DOCTYPE html>
<html>
  <head>
    <base href="./" />

    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    <link rel="icon" id="app_icon" type="image/png" href="/assets/favicon.png" />
  </head>

  <body class="h-full min-h-full">
    <div>
      <app-www-landing-header>
        <!-- Menu Header -->
      </app-www-landing-header>

      <router-outlet>
        <!-- Page content -->
      </router-outlet>
    </div>

    <app-www-landing-footer>
      <!-- Footer -->
    </app-www-landing-footer>
  </body>
</html>
